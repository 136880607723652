<template>
  <Main>
    <ToolsPanel
      :breadCrumbFirst="'Users'"
      :breadCrumbFirstLink="getFirstLink"
      :breadCrumbSecond="'User Edit'"
      :title="'User Edit'"
    >
      <div class="form-create__buttons">
        <MainButton buttonWhite @click.native="handleSubmit">Edit</MainButton>
        <MainButton @click.native="handleBackToList">Back</MainButton>
      </div>
    </ToolsPanel>
    <ContentContainer>
      <div class="form-create">
        <form @submit.prevent="handleSubmit">
          <p class="form-create__label">Name</p>
          <div class="input-container">
            <InputText
              :onChange="setName"
              :id="'name'"
              :label="'name'"
              :type="'text'"
              :value="formDataToSend.name"
              :placeholder="'Name'"
              :error-messages="validationErrors['name']"
              isBordered
            />
          </div>
          <p class="form-create__label">Email</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'email'"
              :label="'email'"
              :type="'text'"
              :placeholder="'Email'"
              :error-messages="validationErrors['email']"
              isBordered
            />
          </div>
          <p class="form-create__label">Password</p>
          <div class="input-container">
            <InputText
              :set-value="formDataToSend"
              :id="'password'"
              :label="'password'"
              :type="'password'"
              :placeholder="'Password'"
              :error-messages="validationErrors['password']"
              isBordered
            />
          </div>

          <!-- <div class="input-container">
            <MainCheckbox :label="'Активный'" :id="'is_active'" :set-value="formDataToSend" />
          </div> -->

          <div v-if="error.message != undefined" class="form-create__error">
            {{ error.message }}
          </div>
          <div class="form-create__btn">
            <MainButton :type="'submit'">Edit</MainButton>
          </div>
        </form>
        <ModalError
          :error-modal="false"
          :handle-close-error-modal="
            () => {
              this.error = {};
            }
          "
        />
      </div>
    </ContentContainer>
    <FetchSpinnerModal v-if="loadingProcess" />
  </Main>
</template>

<script>
import { mapState } from "vuex";
import ROUTE from "~/constants/routes";
import usersApi from "~/api/user";

import Main from "~/templates/Main.vue";
import FetchSpinnerModal from "~/components/molecules/FetchSpinnerModal.vue";
import ToolsPanel from "~/components/molecules/ToolsPanel.vue";
import ContentContainer from "~/components/molecules/ContentContainer.vue";
import MainButton from "~/components/atoms/buttons/MainButton.vue";
import InputText from "~/components/atoms/inputs/InputText.vue";
import ModalError from "~/components/molecules/ModalError.vue";
// import MainCheckbox from "~/components/atoms/inputs/MainCheckbox.vue";

export default {
  name: "UserEdit",
  metaInfo: {
    title: "User Edit",
  },
  data() {
    return {
      formDataToSend: {
        name: "",
        email: "",
        password: "",
        is_active: false,
      },
    };
  },
  components: {
    Main,
    FetchSpinnerModal,
    ToolsPanel,
    ContentContainer,
    MainButton,
    InputText,
    ModalError,
    // MainCheckbox,
  },
  mounted() {
    if (this.getId == 1) {
      console.log("hello");
      this.$router.push({
        path: ROUTE.USERS_LIST.replace(":page", 1),
      });
    }
    this.getItem();
  },
  computed: {
    ...mapState("users", ["error", "loadingProcess", "validationErrors"]),
    getFirstLink() {
      return ROUTE.USERS_LIST.replace(":page", 1);
    },
    getId() {
      return this.$route.params.id;
    },
  },
  methods: {
    handleSubmit() {
      const data = {
        name: this.formDataToSend.name.length ? this.formDataToSend.name.trim() : "",
        email: this.formDataToSend.email.length ? this.formDataToSend.email : "",
        // is_active: this.formDataToSend.is_active,
      };
      if (this.formDataToSend.password.length) {
        data.password = this.formDataToSend.password.length ? this.formDataToSend.password : "";
      }
      const url = `/${this.getId}`;
      this.$store.dispatch("users/editUser", { url: url, editData: data });
    },
    handleBackToList() {
      this.$router.push({
        path: ROUTE.USERS_LIST.replace(":page", 1),
      });
    },
    setName(value) {
      this.formDataToSend.name = value.trim();
    },
    getItem() {
      const url = `/${this.getId}`;
      try {
        usersApi.getItemUser(url).then((res) => {
          this.$store.commit("users/setError", {});
          this.$store.commit("users/setValidationErrors", {});
          const data = res.data;
          this.formDataToSend.name = data.name;
          this.formDataToSend.email = data.email;
          // this.formDataToSend.is_active = data.isActive;
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
